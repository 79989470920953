
import {
  defineComponent,
  ref,
  toRefs,
  computed,
  watch
} from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { DEFAULT_PAGE_SIZE, getAnnouncementSpecifiedUsers, ResponseError } from '@/services/api';

export default defineComponent({
  props: {
    announcementId: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { announcementId, modelValue } = toRefs(props);
    const isVisible = computed({
      get: () => modelValue.value,
      set: (isVisible) => emit('update:modelValue', isVisible)
    });

    const data = ref();
    const page = ref(1);
    const pageSize = ref(DEFAULT_PAGE_SIZE);

    const isLoading = ref(false);
    const fetchTargetUsers = async() => {
      isLoading.value = true;

      try {
        data.value = await getAnnouncementSpecifiedUsers({
          announcementId: announcementId.value,
          query: {
            page: page.value,
            pageSize: pageSize.value
          }
        });
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data?.error?.message);
      }

      isLoading.value = false;
    };

    watch(isVisible, (isVisible) => {
      if (isVisible) fetchTargetUsers();
    });

    return {
      page,
      data,
      isLoading,
      isVisible
    };
  }
});
