
import { computed, ComputedRef, defineComponent, ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useAnnouncements, usePatchAnnouncement } from '@/composables/api';
import {
  AnnouncementIdOption,
  DEFAULT_PAGE_SIZE,
  IntBoolean,
  ResponseError,
  OrderSortKey
} from '@/services/api';
import permissionUnits from '@/components/permission-units/index.vue';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { getStatusTagType } from '@/utils/render';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { AnnouncementStatus, AnnouncementType, TargetUserType } from '@/interfaces';
import { normalizePatternedString } from '@/utils/normalization';
import TargetUsersDialog from './components/TargetUsersDialog.vue';
import { sortBy } from 'lodash';
import { useKeyword, usePage, useSort } from '@/composables';
import { useLang } from './composables';

const translate = {
  SYSTEM: '系統通知',
  EVENT: '活動通知',
  ALL: '全部會員',
  NEW: '新會員',
  SPECIFIC: '指定會員',
  SCHEDULING: '待發佈',
  PUBLISHED: '已發佈',
  EXPIRED: '已過期'
};

export default defineComponent({
  components: {
    SearchBox,
    permissionUnits,
    Filter,
    TargetUsersDialog
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const dateRange = ref();
    const startedAt = ref();
    const endedAt = ref();
    const type = ref();
    const targetUsers = ref();
    const isEnabled = ref();
    const isTop = ref();
    const lang = ref();
    const status = ref();
    const filterEvent = ref();
    const isTargetUsersDialogVisible = ref(false);
    const currentAnnouncementId = ref();

    const { sortKey, sortOrder } = useSort<OrderSortKey>();
    const { langMap, langs } = useLang();
    const { data, isLoading, isFetching, refetch } = useAnnouncements({
      page,
      pageSize,
      startedAt,
      endedAt,
      keyword,
      type,
      targetUsers,
      isEnabled,
      isTop,
      status,
      sort: sortOrder,
      sortKey,
      lang
    });
    const { mutate: patchAnnouncement } = usePatchAnnouncement();

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '類型',
          placeholder: '請選擇',
          options: Object
            .entries(AnnouncementType)
            .map(([label, value]) => ({ label: translate[label], value }))
        },
        {
          type: FilterType.SELECTOR,
          label: '發佈類型',
          placeholder: '請選擇',
          options: Object
            .entries(TargetUserType)
            .map(([label, value]) => ({ label: translate[label], value }))
        },
        {
          type: FilterType.SELECTOR,
          label: '狀態',
          placeholder: '請選擇',
          options: Object
            .entries(AnnouncementStatus)
            .map(([label, value]) => ({ label: translate[label], value }))
        },
        {
          type: FilterType.CHECKBOX,
          label: '常態型'
        },
        {
          type: FilterType.SELECTOR,
          label: '開關',
          placeholder: '請選擇',
          options: [
            {
              label: '開啟',
              value: `${IntBoolean.TRUE}`
            },
            {
              label: '關閉',
              value: `${IntBoolean.FALSE}`
            }
          ]
        },
        {
          type: FilterType.SELECTOR,
          label: '置頂過濾標籤',
          placeholder: '請選擇',
          options: [
            {
              label: '是',
              value: `${IntBoolean.TRUE}`
            },
            {
              label: '否',
              value: `${IntBoolean.FALSE}`
            }
          ]
        },
        {
          type: FilterType.SELECTOR,
          label: '語系',
          placeholder: '請選擇',
          options: langs.value.map(({ name, code }) => ({
            label: name,
            value: code
          }))
        }
      ];
    });

    const hasIsTop = computed(() => data.value?.data?.find(item => item.isTop === true));
    const filteredData = computed(() => {
      if (hasIsTop.value) return sortBy(data.value.data, ({ isTop }) => isTop ? 0 : 1);
      return data.value?.data || [];
    });

    const handleDateChange = () => {
      if (!dateRange.value) {
        startedAt.value = null;
        endedAt.value = null;
        return;
      };
      startedAt.value = formatISOString(dateRange.value[0]);
      endedAt.value = formatISOString(dateRange.value[1]);
    };

    const handleFilterChange = (event: FilterEvent) => {
      filterEvent.value = event;

      type.value = normalizePatternedString(event[0]);
      targetUsers.value = normalizePatternedString(event[1]);
      status.value = normalizePatternedString(event[2]);
      isEnabled.value = normalizePatternedString(event[4]);
      isTop.value = normalizePatternedString(event[5]);
      lang.value = normalizePatternedString(event[5]);

      if (!dateRange.value && event[3]) {
        endedAt.value = '';
      } else if (!dateRange.value && !event[3]) {
        endedAt.value = null;
      };
    };

    const openTargetUsersDialog = async({ announcementId }: AnnouncementIdOption) => {
      currentAnnouncementId.value = announcementId;
      isTargetUsersDialogVisible.value = true;
    };

    const handleIsTop = (announcementId, isTop) => {
      const data = { isTop };

      patchAnnouncement(
        { announcementId, data }, {
          onSuccess() {
            refetch.value();
          },
          onError(error: ResponseError) {
            ElMessage.error(error.response?.data.message);
          }
        }
      );
    };

    watch(
      isTargetUsersDialogVisible,
      (isTargetUsersDialogVisible) => {
        const isDialogClosed = !isTargetUsersDialogVisible;
        if (isDialogClosed) refetch.value();
      });

    watch(dateRange, () => {
      if (filterEvent.value) {
        handleFilterChange(filterEvent.value);
      }
      refetch.value();
    });

    watch([startedAt, endedAt], ([startedAt, endedAt]) => {
      router.push({ query: { ...route.query, startedAt, endedAt } });
    });

    watch(
      () => route.query,
      (query) => {
        startedAt.value = query.startedAt as string;
        endedAt.value = query.endedAt as string;

        // 避免[null,null], datePicker會顯示當天日期
        if (startedAt.value && endedAt.value) {
          dateRange.value = [startedAt.value, endedAt.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    return {
      page,
      data,
      filteredData,
      pageSize,
      isLoading,
      dateRange,
      isFetching,
      filterOptions,
      TargetUserType,
      currentAnnouncementId,
      isTargetUsersDialogVisible,
      hasIsTop,
      setPageQuery,
      formatLocalTime,
      getStatusTagType,
      handleDateChange,
      handleFilterChange,
      openTargetUsersDialog,
      handleIsTop,
      langMap,
      keyword
    };
  }
});
